/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #cbd5e0;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #cbd5e0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
  border: 2px solid #cbd5e0;
}

.custom-tooltip {
  opacity: 1 !important;
  background-color: transparent !important;
}

@keyframes subtleGlow {
  0%, 100% {
    box-shadow: 0 0 10px rgba(74, 158, 255, 0.3), 0 0 20px rgba(74, 158, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 15px rgba(69, 198, 255, 0.4), 0 0 30px rgba(69, 198, 255, 0.3);
  }
}

@keyframes borderTravel {
  0%, 100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
}

.active-farming-job {
  position: relative;
  animation: subtleGlow 3s ease-in-out infinite;
}

.active-farming-job::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(90deg, #4a9eff, #45c6ff, #4a9eff);
  background-size: 300% 300%;
  animation: borderTravel 4s linear infinite;
  z-index: -1;
  border-radius: inherit;
  filter: blur(4px);
}